import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ onLogout }) => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/start-grid">Long Bot</Link>
        <Link to="/start-short-grid">Short Bot</Link>
        <Link to="/active-bot">Active</Link>
        {/* <Link to="/history">History</Link> */}
      </div>
      <div className="navbar-right">
        <button onClick={onLogout}>Logout</button>
      </div>
    </nav>
  );
};

export default Navbar;