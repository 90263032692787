import React, { useEffect, useState } from 'react';
import './ActiveGrid.css';

function ActiveGrid() {
  const [grids, setGrids] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGridData = async () => {
      try {
        const response = await fetch('https://api.oneclickalgo.com/get_active_grid_data', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("API Data:", data);  // Log the API response

        // Parse the JSON string values in the response
        const parsedData = {};
        Object.keys(data).forEach((key) => {
          parsedData[key] = JSON.parse(data[key]);
        });

        setGrids(parsedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchGridData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check if the grids object is empty
  if (Object.keys(grids).length === 0) {
    return <div>You have no grid running.</div>;
  }

  return (
    <div className="grid-container">
      {Object.entries(grids).map(([filename, grid]) => (
        <div className="grid-widget" key={grid.grid_id}>
          <h3>(ID: {grid.tradingSymbol})</h3>
          <p><strong>Status:</strong> {grid.status}</p>
          <p><strong>Upper Limit:</strong> {grid.upperLimit}</p>
          <p><strong>Lower Limit:</strong> {grid.lowerLimit}</p>
          <p><strong>Grids:</strong> {grid.grids.join(', ')}</p>
          <p><strong>Symbol Token:</strong> {grid.symbolToken}</p>
          <p><strong>Intraday:</strong> {grid.isIntraday ? 'Yes' : 'No'}</p>
          <details>
            <summary>Order Book History</summary>
            <pre>{JSON.stringify(grid.orderBookHistory, null, 2)}</pre>
          </details>
        </div>
      ))}
    </div>
  );
}

export default ActiveGrid;
