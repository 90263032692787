import React from 'react';
import './AlgorithmDescription.css';

const AlgorithmDescription = () => {
  const description = [
    "Spot Grid Trading is an automated trading strategy that capitalizes on price fluctuations within a defined range.",
    "The algorithm places a series of buy and sell orders at predetermined price levels, forming a grid.",
    "As the market price moves up and down within this range, the bot automatically buys low and sells high.",
    "This strategy is particularly effective in sideways or ranging markets, generating profits from price volatility.",
    "Our Grid-Trading Bot implements this strategy with advanced features and risk management, working effectively for indices, cash markets, and even in Futures & Options (F&O)."
  ];

  const parameters = [
    { name: "Trading Symbol", description: "The symbol of the asset to be traded (e.g., NIFTY50, RELIANCE, BANKNIFTY)" },
    { name: "Number of Lots", description: "The number of lots to be traded in each grid order" },
    { name: "Upper Limit", description: "The highest price in the trading range" },
    { name: "Lower Limit", description: "The lowest price in the trading range" },
    { name: "Number of Grids", description: "The number of price levels within the range" },
    { name: "Take Profit", description: "The percentage of profit at which to close positions" },
    { name: "Stop Loss", description: "The percentage of loss at which to exit the strategy" }
  ];

  return (
    <div className="algorithm-description">
      <h2>Grid-Trading Bot Algorithm</h2>
      <div className="description">
        {description.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
      <h3>Required Parameters</h3>
      <ul className="parameters-list">
        {parameters.map((param, index) => (
          <li key={index}>
            <strong>{param.name}:</strong> {param.description}
          </li>
        ))}
      </ul>

      <div className="grid-visualization">
        <svg width="100%" height="400" viewBox="0 0 600 400">
          <defs>
            <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
              <polygon points="0 0, 10 3.5, 0 7" />
            </marker>
          </defs>
          {/* X and Y axis */}
          <line x1="50" y1="350" x2="550" y2="350" stroke="black" strokeWidth="2" markerEnd="url(#arrowhead)" />
          <line x1="50" y1="50" x2="50" y2="350" stroke="black" strokeWidth="2" markerEnd="url(#arrowhead)" />
          <text x="300" y="390" textAnchor="middle">Time</text>
          <text x="10" y="200" textAnchor="middle" transform="rotate(-90 10,200)">Asset Price</text>

          {/* Price movements with an updated color scheme */}
          <path className="price-line" d="M50 200 Q 150 100 300 250 T 550 150" fill="none" stroke="blue" strokeWidth="2" />

          {/* Grid Lines */}
          <line x1="50" y1="75" x2="550" y2="75" stroke="#ff9800" strokeWidth="1" strokeDasharray="5,5" />
          <line x1="50" y1="137.5" x2="550" y2="137.5" stroke="#ff9800" strokeWidth="1" strokeDasharray="5,5" />
          <line x1="50" y1="200" x2="550" y2="200" stroke="#ff9800" strokeWidth="1" strokeDasharray="5,5" />
          <line x1="50" y1="262.5" x2="550" y2="262.5" stroke="#ff9800" strokeWidth="1" strokeDasharray="5,5" />
          <line x1="50" y1="325" x2="550" y2="325" stroke="#ff9800" strokeWidth="1" strokeDasharray="5,5" />

          {/* Upper and Lower Limit Labels */}
          <text x="570" y="75" fill="#4caf50">Upper Limit</text>
          <text x="570" y="325" fill="#f44336">Lower Limit</text>

          {/* Buy and Sell points */}
          <circle className="buy-point" cx="100" cy="262.5" r="5" fill="#4caf50" />
          <text x="110" y="257.5" fill="#4caf50">Buy</text>
          <circle className="sell-point" cx="200" cy="137.5" r="5" fill="#f44336" />
          <text x="210" y="132.5" fill="#f44336">Sell</text>
          <circle className="buy-point" cx="300" cy="262.5" r="5" fill="#4caf50" />
          <text x="310" y="257.5" fill="#4caf50">Buy</text>
          <circle className="sell-point" cx="400" cy="137.5" r="5" fill="#f44336" />
          <text x="410" y="132.5" fill="#f44336">Sell</text>
          <circle className="buy-point" cx="500" cy="200" r="5" fill="#4caf50" />
          <text x="510" y="195" fill="#4caf50">Buy</text>
        </svg>
      </div>

      <div className="explanation">
        <h3>How It Works</h3>
        <ol>
          <li>The bot sets up a grid of buy and sell orders within the defined price range (Upper and Lower Limits) for the specified Trading Symbol.</li>
          <li>Each grid order is placed for the specified Number of Lots.</li>
          <li>As the asset price fluctuates, it triggers these orders:</li>
          <ul>
            <li>When price drops to a lower grid line, a buy order is executed (marked in green).</li>
            <li>When price rises to a higher grid line, a sell order is executed (marked in red).</li>
          </ul>
          <li>This process repeats, accumulating small profits with each completed buy-sell cycle.</li>
          <li>The strategy benefits from volatility, as more price movement generates more trading opportunities.</li>
          <li>Risk is managed through careful parameter selection and built-in Take Profit and Stop Loss mechanisms.</li>
          <li>This strategy can be applied to various market instruments including indices, stocks in the cash market, and derivatives in the F&O segment.</li>
        </ol>
      </div>
    </div>
  );
};

export default AlgorithmDescription;
