import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './StartGrid.css'; // Make sure to have the styles from your previous CSS in this file

function StartGrid({ onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    upperLimit: 0.0,
    lowerLimit: 0.0,
    grids: 0,
    tradingsymbol: '',
    lots: 0,
    isIntraday: false,
    trailUp: false,
    trailUpValue: 0,
    stoploss: false,
    stoplossValue: 0,
    takeProfit: false,
    botType: 'LONG',
    takeProfitValue: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const animation = useSpring({
    from: { opacity: 0, transform: 'translateY(-50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);

    try {
      const response = await fetch('https://api.oneclickalgo.com/get_margin_required', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'Cx1KIizcYmauU25Zhr8JT7a2NbGhrsDw1rcafoAd',
        },
        body: JSON.stringify(formData),
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setResponseData(result);
      setShowDialog(true);
    } catch (error) {
      if (error.name === 'AbortError') {
        alert('Request timed out');
      } else {
        alert(`Error: ${error.message || 'Unknown error'}`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    try {
      const response = await fetch('https://api.oneclickalgo.com/start_bot', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'Cx1KIizcYmauU25Zhr8JT7a2NbGhrsDw1rcafoAd',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert('Grid bot started successfully! Response: ' + JSON.stringify(result));
      setShowDialog(false);
      onSubmit();
    } catch (error) {
      alert(`Error starting grid bot: ${error.message || 'Unknown error'}`);
    }
  };

  return (
    <animated.div style={animation} className="start-grid">
      <h2>Start Grid Bot</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="upperLimit">Upper Limit</label>
          <input
            type="number"
            step="0.01"
            id="upperLimit"
            name="upperLimit"
            value={formData.upperLimit}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lowerLimit">Lower Limit</label>
          <input
            type="number"
            step="0.01"
            id="lowerLimit"
            name="lowerLimit"
            value={formData.lowerLimit}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="grids">Number of Grids</label>
          <input
            type="number"
            id="grids"
            name="grids"
            value={formData.grids}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="tradingsymbol">Trading Symbol</label>
          <input
            type="text"
            id="tradingsymbol"
            name="tradingsymbol"
            value={formData.tradingsymbol}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lots">Lots</label>
          <input
            type="number"
            id="lots"
            name="lots"
            value={formData.lots}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group intraday-checkbox">
          <input
            type="checkbox"
            id="isIntraday"
            name="isIntraday"
            checked={formData.isIntraday}
            onChange={handleChange}
          />
          <label htmlFor="isIntraday">Intraday</label>
        </div>

        <div className="form-group">
          <input
            type="checkbox"
            id="trailUp"
            name="trailUp"
            checked={formData.trailUp}
            onChange={handleChange}
          />
          <label htmlFor="trailUp">Trail Up</label>
          {formData.trailUp && (
            <input
              type="number"
              name="trailUpValue"
              value={formData.trailUpValue}
              onChange={handleChange}
              placeholder="Enter Trail Up value"
              required
            />
          )}
        </div>

        <div className="form-group">
          <input
            type="checkbox"
            id="stoploss"
            name="stoploss"
            checked={formData.stoploss}
            onChange={handleChange}
          />
          <label htmlFor="stoploss">Stoploss</label>
          {formData.stoploss && (
            <input
              type="number"
              name="stoplossValue"
              value={formData.stoplossValue}
              onChange={handleChange}
              placeholder="Enter Stoploss value"
              required
            />
          )}
        </div>

        <div className="form-group">
          <input
            type="checkbox"
            id="takeProfit"
            name="takeProfit"
            checked={formData.takeProfit}
            onChange={handleChange}
          />
          <label htmlFor="takeProfit">Take Profit</label>
          {formData.takeProfit && (
            <input
              type="number"
              name="takeProfitValue"
              value={formData.takeProfitValue}
              onChange={handleChange}
              placeholder="Enter Take Profit value"
              required
            />
          )}
        </div>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Starting...' : 'Start Grid Bot'}
        </button>
      </form>

      {showDialog && (
        <div className="dialog-overlay">
          <div className="dialog">
            <h3>Confirm Grid Bot Start</h3>
            <pre>{JSON.stringify(responseData, null, 2)}</pre>
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={() => setShowDialog(false)}>Cancel</button>
          </div>
        </div>
      )}
    </animated.div>
  );
}

export default StartGrid;
