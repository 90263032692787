import axios from 'axios';

const API_BASE_URL = 'https://api.oneclickalgo.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const login = (apiKey, username, password, token) =>
  api.post('/login', { apiKey, username, password, token });

export const logout = () => api.post('/logout');

export const checkSession = () => api.get('/check_session');

export const getPosition = () => api.get('/get_position');

export const startBot = (params) => api.post('/start_bot', params);

export const closeAllOpenOrders = (tradingsymbol) =>
  api.post('/close_all_open_orders', { tradingsymbol });

export const closeAllPositions = (tradingsymbol) =>
  api.post('/close_all_positions', { tradingsymbol });

export default api;