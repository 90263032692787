import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import StartGrid from './components/StartGrid';
import StartShortGrid from './components/StartShortGrid';
import ActiveGrid from './components/ActiveGrid';
import Navbar from './components/Navbar';
// import './styles/theme.css';  // Import the new theme CSS
import './App.css';

const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

const PrivateLayout = ({ onLogout }) => {
  return (
    <div className="app-container">
      <Navbar onLogout={onLogout} />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
};

const App = () => {
  const { isAuthenticated, logout: authLogout, isLoading } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const success = await authLogout();
    if (success) {
      navigate('/login');
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<PrivateLayout onLogout={handleLogout} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="start-grid" element={<StartGrid />} />
            <Route path="start-short-grid" element={<StartShortGrid />} />
            <Route path="active-bot" element={<ActiveGrid />} />
            {/* Redirect any unknown route to the home page */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Route>
        <Route
          index
          element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />
          }
        />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
