import { useState, useEffect } from 'react';
import { login as apiLogin, logout as apiLogout, checkSession } from '../utils/api';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const response = await checkSession();
      setIsAuthenticated(response.data.status);
    } catch (error) {
      console.error('Auth check error:', error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (apiKey, username, password, token) => {
    try {
      const response = await apiLogin(apiKey, username, password, token);
      if (response.data.status === true && response.data.message === "Login successful") {
        setIsAuthenticated(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await apiLogout();
      setIsAuthenticated(false);
      return true; // Indicate successful logout
    } catch (error) {
      console.error('Logout error:', error);
      return false; // Indicate failed logout
    }
  };

  return { isAuthenticated, isLoading, login, logout, checkAuth };
};