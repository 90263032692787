import React, { useState } from 'react';
import { getPosition } from '../utils/api';
import Navbar from './Navbar';
import AlgorithmDescription from './AlgorithmDescription';
import CloseAllOpenOrders from './CloseAllOpenOrders';
import CloseAllPositions from './CloseAllPositions';
import OrderHistoryPositions from './OrderHistoryPositions';
import StartGrid from './StartGrid';

const Dashboard = ({ onLogout }) => {
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <div className="dashboard">
      
      
      <div className="dashboard-components">
        <AlgorithmDescription />
        {/* <StartGrid onSubmit={handleRefresh} />
        <CloseAllOpenOrders onSubmit={handleRefresh} />
        <CloseAllPositions onSubmit={handleRefresh} />
        <OrderHistoryPositions refresh={refresh} /> */}
      </div>
    </div>
  );
};

export default Dashboard;